@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.TestimonialSliderItem {
	box-sizing: border-box;
	color: var(--color-black);
	background: var(--color-white);

	&___white {
		background: var(--color-white);
		color: var(--color-black);
	}

	&___whiteGrey {
		background: var(--color-white-grey);
		color: var(--color-black);
	}

	&___lightGrey {
		background: var(--color-grey-lightest);
		color: var(--color-black);
	}

	&___lightBlue {
		background: var(--color-blue-lightest);
		color: var(--color-black);
	}

	&___blue {
		background: var(--color-blue);
		color: var(--color-light);
	}

	&___violet {
		background: var(--color-violet);
		color: var(--color-light);
	}

	&___green {
		background: var(--color-green);
		color: var(--color-light);
	}

	&___grey {
		background: var(--color-grey);
		color: var(--color-light);
	}

	&___red {
		background: var(--color-red);
		color: var(--color-light);
	}

	&___active {
		.TestimonialSliderItem_text {
			animation: textDissolve 0.5s cubic-bezier(0.47, 0, 0.75, 0.72) 0.5s both;
		}

		.TestimonialSliderItem_author {
			animation: textDissolve 0.6s cubic-bezier(0.47, 0, 0.75, 0.72) 0.8s both;
		}
	}
}

@keyframes textDissolve {
	from {
		opacity: 0;
		transform: translateY(var(--spacing--base));
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.TestimonialSliderItem_content {
	padding: 18rem 0;
}

.TestimonialSliderItem_heading {
	display: flex;
	width: 100%;
	justify-content: space-between;
	flex-direction: column;

	@include mq("lg") {
		flex-direction: row;
		padding-bottom: 12rem;
	}

}

.TestimonialSliderItem_text {
	font-family: var(--font-primary);
	font-weight: var(--font-weight--normal);
	font-size: var(--font-size--xl);
	line-height: var(--line-height--md);
	letter-spacing: var(--letter-spacing-heading);
	max-width: 105rem;
	width: 100%;
	order: 1;
	padding: var(--spacing--base) 0;

	@include mq("lg") {
		font-size: var(--font-size--4xl);
		order: 0;
		padding: 0;
	}
}

.TestimonialSliderItem_author {
	order: 1;

	@include mq("md") {
		order: 0;
		align-self: flex-end;
		flex-basis: 60%;
	}
}

.TestimonialSliderItem_authorName {
	@include paragraph-md;

	@include mq("md") {
		@include body--lg-med;
	}
}

.TestimonialSliderItem_authorDescription {
	@include paragraph;

	@include mq("md") {
		@include body--lg-reg;
	}
}

.TestimonialSliderItem_authorImage {
	width: var(--spacing--4xl);
	height: var(--spacing--4xl);
	border-radius: 50%;
	overflow: hidden;
	margin-bottom: 1.6rem;
}

.TestimonialSliderItem_navigation {
	display: flex;
	padding-bottom: var(--spacing--base);

	@include mq("lg") {
		padding: 0 0 0 var(--spacing--base);
	}

}

// Theme: Kogp
[data-theme="kogp"] {
	.TestimonialSliderItem_authorName {
		font-weight: var(--font-weight--bold);
	}

	.TestimonialSliderItem_navigation {
		color: var(--color-red-light);
	}
}